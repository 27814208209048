<template>
	<div>
		<div class="is-flex is-justify-content-center mt-3">
			<div v-if="user_store.user.type_id == 0" class="notification is-info is-light has-text-centered no-role-notification px-5">
				<p>
					Administratori duhet te aktivizoje perdoruesin tuaj ne menyre qe te keni te drejta.
				</p>
			</div>
		</div>
		<!-- <pre>
			{{user_store}}
		</pre> -->
		
	</div>
</template>

<script>
// import { Calendar } from 'v-calendar';
// import 'v-calendar/dist/style.css';
// import Api from '@/services/Api'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/stores/user'
export default{
	data() {
		return {
			databases: [],
			reports: [],
			user_store: storeToRefs(useUserStore())
		};
	},

	created(){
		this.$document.title = "Homepage"
	}	
}
</script>

<style> 
.is-small-left-heading{
	position: absolute;
	left: 2px;
	top: 2px;
	font-size: 8px;
}
.left-sided-report{
	position: absolute;
	right: 2px;
}
.no-role-notification{

}
</style>
